import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {Link} from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found"/>
    <div className="flex flex-col justify-center items-center my-32">
      <h1 className="font-title text-5xl tracking-wide">404</h1>
      <span className="font-sans font-light italic text-2xl mb-4">Page not found</span>
      <Link className="bg-black text-white no-underline font-semplicata text-lg uppercase tracking-wide px-4 py-2" to='/'>Back
        to
        homepage</Link>
    </div>
  </Layout>
)

export default NotFoundPage
